<template>
  <div>
    <button type="button" class="btn btn-primary" data-bs-toggle="modal"
            data-bs-target="#paymentModal" @click="updateAmount">
      Купить
    </button>
    <div class="modal fade" id="paymentModal" tabindex="-1" role="dialog" aria-labelledby="paymentModalLabel"
         aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="paymentModalLabel">Выберите способ оплаты</h5>
          </div>
          <div class="modal-body">
            <div class="text-center mb-3">
              <h1 style="font-size: xxx-large">
                <s class="text-secondary" v-if="discountText && discount">{{ amountWithoutDiscount }}</s> {{ amount }} <span
                class="text-secondary small">KZT</span>
              </h1>
              <small v-if="discountText && discount">{{ discountText }} -{{ discount }}% на лицензии по карте</small>
            </div>
            <div class="payment-methods">
              <div :class="'card ' + (selectedCardId === card.id ? 'card-selected' : '')" v-for="card in cards"
                   style="cursor: pointer" @click="selectCard(card)">
                <div class="card-body ">
                  <div class="row">
                    <img :src="'/assets/img/logos/' + card.system_name + '.png'" alt="Visa Logo"
                         class="img-thumbnail col-2 pt-2">
                    <span class="font-monospace col-10 pt-2" style="font-size: 1rem">{{ card.card_mask }}</span>
                  </div>
                  <span class="badge" v-if="card.discount_card.bank_name && discount">-{{ discount }}%</span>
                </div>
              </div>
              <hr>
              <a class="card" style="cursor: pointer" href="/banking">
                <div class="card-body">
                  <div class="row">
                    <div class="col-2">
                      <i class="fa fa-credit-card"></i>
                    </div>
                    <div class="col-10">
                      Добавить банковскую карту
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" :disabled="cards.length === 0 || !selectedCardId || buying"
                    @click="submitForm">
              {{ btnText }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PayModal",
  props: ["cards", "discount"],
  data() {
    return {
      selectedCardId: null,
      btnText: "Купить",
      buying: false,
      discountText: "",
      amount: 0,
      amountWithoutDiscount: 0
    };
  },
  mounted() {
  },
  methods: {
    selectCard(card) {
      if (this.selectedCardId !== card.id) {
        this.selectedCardId = card.id;
        document.getElementById('card_id').value = this.selectedCardId;
        if (card.discount_card.bank_name) {
          this.discountText = card.discount_card.bank_name;
          this.amountWithoutDiscount = this.amount;
          this.amount = this.amountWithoutDiscount - (this.amountWithoutDiscount * (this.discount / 100))
        } else {
          if (this.amountWithoutDiscount) {
            this.amount = this.amountWithoutDiscount;
          }
          this.discountText = "";
        }
      }
    },
    async submitForm() {
      if (this.buying) {
        return
      }
      this.buying = true;
      this.btnText = "Покупаем...";
      let isSuccess = await this.makeInvoice();
      if (isSuccess) {
        this.btnText = "Куплено";
        alert('Ключи успешно приобретены')
        location.href = '/keys';
      } else {
        this.btnText = "Купить";
        this.buying = false;
        alert('Не удалось купить ключи')
      }
    },
    async makeInvoice() {
      const form = document.getElementById('keysAddForm');
      if (form) {
        const formData = new FormData(form);
        let isSuccess = false;

        await fetch("/keys/add", {
          method: 'POST',
          body: formData
        })
          .then(response => response.json())
          .then(data => {
            isSuccess = data.success;
          });

        return isSuccess;
      }

      return '';
    },
    updateAmount() {
      this.selectedCardId = null;
      this.amount = +(document.getElementById('amount').textContent);
    }
  }
}
</script>

<style scoped>

</style>
