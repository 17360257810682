<template>
  <div>
    <button type="button" class="btn btn-sm btn-primary" data-bs-toggle="modal"
            data-bs-target="#paymentModal" @click="calcAmount">
      Продлить
    </button>
    <div class="modal fade" id="paymentModal" tabindex="-1" role="dialog" aria-labelledby="paymentModalLabel"
         aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="paymentModalLabel">Выберите способ оплаты</h5>
          </div>
          <div class="modal-body">
            <div class="text-center mb-3">
              <h1 style="font-size: xxx-large" v-if="amount"><s class="text-secondary"
                                                                v-if="amountWithoutDiscount && discount">{{ amountWithoutDiscount }}</s>
                {{ amount }} <span class="text-secondary small">KZT</span></h1>
              <h1 style="font-size: xxx-large" v-else><i class="fas fa-spin fa-spinner"></i> <span
                class="text-secondary small">KZT</span></h1>
              <small class="text-danger" v-if="errorText">{{ errorText }}</small>
              <small v-if="discountText && discount">{{ discountText }} -{{ discount }}% на лицензии по карте</small>
            </div>
            <div class="payment-methods">
              <div :class="'card ' + (selectedCardId === card.id ? 'card-selected' : '')" v-for="card in cards"
                   style="cursor: pointer" @click="selectCard(card)">
                <div class="card-body ">
                  <div class="row">
                    <img :src="'/assets/img/logos/' + card.system_name + '.png'" alt="Visa Logo"
                         class="img-thumbnail col-2 pt-2">
                    <span class="font-monospace col-10 pt-2" style="font-size: 1rem">{{ card.card_mask }}</span>
                  </div>
                  <span class="badge" v-if="card.discount_card.bank_name && discount">-{{ discount }}%</span>
                </div>
              </div>
              <hr>
              <a class="card" style="cursor: pointer" href="/banking">
                <div class="card-body">
                  <div class="row">
                    <div class="col-2">
                      <i class="fa fa-credit-card"></i>
                    </div>
                    <div class="col-10">
                      Добавить банковскую карту
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary"
                    :disabled="cards.length === 0 || !selectedCardId || buying || !amount"
                    @click="submitForm">
              {{ btnText }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProlongModal",
  props: ["cards", "discount"],
  data() {
    return {
      selectedCardId: null,
      btnText: "Продлить",
      buying: false,
      discountText: "",
      amount: 0,
      amountWithoutDiscount: 0,
      errorText: '',
    };
  },
  mounted() {
  },
  methods: {
    selectCard(card) {
      if (this.selectedCardId !== card.id) {
        this.selectedCardId = card.id;
        document.getElementById('card_id').value = this.selectedCardId;
        this.discountText = "";
        if (card.discount_card.bank_name) {
          this.discountText = card.discount_card.bank_name;
        }
        if (this.discountText) {
          this.amountWithoutDiscount = this.amount;
          this.amount = this.amount - (this.amount * (this.discount / 100));
        } else {
          if (this.amountWithoutDiscount) {
            this.amount = this.amountWithoutDiscount;
            this.amountWithoutDiscount = 0;
          }
        }
      }
    },
    async submitForm() {
      if (this.buying) {
        return
      }
      this.buying = true;
      this.btnText = "Покупаем...";
      let result = await this.prolongKeys();
      if (result?.success) {
        this.btnText = "Продлены";
        alert('Ключи успешно продлены')
        location.reload();
      } else {
        this.btnText = "Продлить";
        this.buying = false;
        alert(result?.message ?? 'Не удалось продлить ключи')
      }
    },
    async prolongKeys() {
      const form = document.getElementById('keysProlongForm');
      if (form) {
        const formData = new FormData(form);
        formData.set('extend', 1);
        let result = null;

        await fetch("/keys/", {
          method: 'POST',
          body: formData,
          headers: {
            'Accept': 'application/json'
          }
        })
          .then(response => response.json())
          .then(data => {
            result = data;
          });

        return result;
      }

      return '';
    },
    async calcAmount() {
      this.selectedCardId = null;
      this.amount = 0;
      this.amountWithoutDiscount = 0;
      this.errorText = '';
      const form = document.getElementById('keysProlongForm');
      let result = null;
      if (form) {
        const formData = new FormData(form);
        await fetch("/keys/calc", {
          method: 'POST',
          body: formData,
          headers: {
            'Accept': 'application/json'
          }
        })
          .then(response => response.json())
          .then(data => {
            result = data;
          });
      }

      if (result?.success) {
        if (result.amount === 0) {
          this.errorText = 'Не выбран ключ';
        }
        this.amount = result.amount;
      } else {
        this.errorText = result?.message ?? 'Ошибка при отправке запроса';
      }
    }
  }
}
</script>

<style scoped>

</style>
